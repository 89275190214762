var pswpElement = document.querySelectorAll('.pswp')[0];

// build items array
var items = [
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-1.jpg',
      w: 1740,
      h: 1213
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-2.jpg',
      w: 1920,
      h: 1443
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-3.jpg',
      w: 1920,
      h: 1454
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-4.jpg',
      w: 1920,
      h: 1496
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-5.jpg',
      w: 1920,
      h: 1439
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-6.jpg',
      w: 1920,
      h: 1440
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-7.jpg',
      w: 1920,
      h: 1415
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-8.jpg',
      w: 1422,
      h: 1920
    }
];

// define options (if needed)
var options = {
    // optionName: 'option value'
    // for example:
    index: 0, // start at first slide
    bgOpacity: 0.9
};

// Initializes and opens PhotoSwipe
$('#gallery_storia').on('click', function(){
  var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.init();
});