(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
$(document).foundation();

// Prevent small screen page refresh sticky bug
$(window).on('sticky.zf.unstuckfrom:bottom', function(e) {
  if (!Foundation.MediaQuery.atLeast('medium')) {
    $(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
  }
});

// By default orbit will only calculate height on page load
// this forces it to calculate height as soon as this modal
// is opened
// $('#gallery_storia').on('opened', function(){
//   $(window).trigger('resize.fndtn.orbit');
// });

var animateHTML = function () {
  var elems,
    windowHeight;
  var init = function () {
    elems = document.getElementsByClassName('aa-get-fade');
    windowHeight = window.innerHeight;
    _addEventHandlers();
    _checkPosition();
  }
  var _addEventHandlers = function () {
    window.addEventListener('scroll', _checkPosition)
    window.addEventListener('resize', init)
  }
  var _checkPosition = function () {
    for (var i = 0; i < elems.length; i++) {
      var posFromTop = elems[i].getBoundingClientRect().top;
      if (posFromTop - windowHeight <= 0) {
        elems[i].className = elems[i].className.replace('aa-get-fade', 'aa-get-fade-done');
      }
    }
  }
  return {
    init: init
  }
}
animateHTML().init()
},{}],2:[function(require,module,exports){

},{}],3:[function(require,module,exports){
var pswpElement = document.querySelectorAll('.pswp')[0];

// build items array
var items = [
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-1.jpg',
      w: 1740,
      h: 1213
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-2.jpg',
      w: 1920,
      h: 1443
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-3.jpg',
      w: 1920,
      h: 1454
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-4.jpg',
      w: 1920,
      h: 1496
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-5.jpg',
      w: 1920,
      h: 1439
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-6.jpg',
      w: 1920,
      h: 1440
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-7.jpg',
      w: 1920,
      h: 1415
    },
    {
      src: '/assets/images/chi-siamo/la-storia/terme-fontecchio_foto-storiche-8.jpg',
      w: 1422,
      h: 1920
    }
];

// define options (if needed)
var options = {
    // optionName: 'option value'
    // for example:
    index: 0, // start at first slide
    bgOpacity: 0.9
};

// Initializes and opens PhotoSwipe
$('#gallery_storia').on('click', function(){
  var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.init();
});
},{}]},{},[1,2,3])

