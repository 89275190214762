$(document).foundation();

// Prevent small screen page refresh sticky bug
$(window).on('sticky.zf.unstuckfrom:bottom', function(e) {
  if (!Foundation.MediaQuery.atLeast('medium')) {
    $(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
  }
});

// By default orbit will only calculate height on page load
// this forces it to calculate height as soon as this modal
// is opened
// $('#gallery_storia').on('opened', function(){
//   $(window).trigger('resize.fndtn.orbit');
// });

var animateHTML = function () {
  var elems,
    windowHeight;
  var init = function () {
    elems = document.getElementsByClassName('aa-get-fade');
    windowHeight = window.innerHeight;
    _addEventHandlers();
    _checkPosition();
  }
  var _addEventHandlers = function () {
    window.addEventListener('scroll', _checkPosition)
    window.addEventListener('resize', init)
  }
  var _checkPosition = function () {
    for (var i = 0; i < elems.length; i++) {
      var posFromTop = elems[i].getBoundingClientRect().top;
      if (posFromTop - windowHeight <= 0) {
        elems[i].className = elems[i].className.replace('aa-get-fade', 'aa-get-fade-done');
      }
    }
  }
  return {
    init: init
  }
}
animateHTML().init()